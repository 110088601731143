// @optimization
@mixin while-transition() {
  .Transition_slide:not(.Transition_slide-active) & {
    @content;
  }
}

@mixin adapt-padding-to-scrollbar($padding) {
  padding-inline-end: calc($padding - var(--scrollbar-width));
}

@mixin adapt-margin-to-scrollbar($margin) {
  margin-inline-end: calc($margin - var(--scrollbar-width));
}


@mixin reset-range() {
  input[type="range"] {
    -webkit-appearance: none;
    display: block;
    width: 100%;
    height: 0.75rem;
    margin-bottom: 0.5rem;
    background: transparent;

    &:focus {
      outline: none;
    }

    &::-ms-track {
      width: 100%;
      cursor: var(--custom-cursor, pointer);

      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
    }

    &::-moz-slider-thumb {
      -moz-appearance: none;
    }

    &::-webkit-slider-runnable-track {
      cursor: var(--custom-cursor, pointer);
    }

    &::-moz-range-track, &::-moz-range-progress {
      cursor: var(--custom-cursor, pointer);
    }
  }
}

@mixin header-mobile {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 2.875rem;
  box-shadow: 0 0.125rem 0.125rem var(--color-light-shadow);

  display: flex;
  flex-direction: row-reverse;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: max(0.75rem, env(safe-area-inset-left));
  padding-right: max(0.5rem, env(safe-area-inset-right));
  background: var(--color-background);

  // Target: Old Firefox (Waterfox Classic)
  @supports not (padding-left: max(0.75rem, env(safe-area-inset-left))) {
    padding-left: 0.75rem;
    padding-right: 0.5rem;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -0.1875rem;
    left: 0;
    right: 0;
    height: 0.125rem;
    box-shadow: 0 0.125rem 0.125rem var(--color-light-shadow);
  }
}
